import axios from 'axios'


const init = {
  'alarm_set':false,
  'user':false,
  'user_group':false,
  'group':false,
  'group_user':false,
  'group_gateway':false,
  'gateway':false,
  'gateway_sensor':false,
  'gateway_group':false,
  'group_attribute':false,
  'gateway_attribute':false,
  'user_attribute':false,
  'gateway_control_reading':false,
  'gateway_queued_message':false,
  'gateway_sent_message':false,
  'gateway_me':false,
  'gateway_comm_usage':false,
  'sensor_readings':false,
  'sensor_attribute':false,
  'dropdowns': false,
  'gateway_overview': false,
  'sensor_overview': false,
}

const dropdown_init = {
  dropdowns: {
    alarm_type: [],
    sensor_data: [],
    alarm_action_type: [],
    sensor_model:[],
  },
  dropdown_values: {
    alarm_type: [],
    sensor_data: [],
    alarm_action_type: [],
    sensor_model: [],
  }
}

export const apgList = {
  namespaced: true,
  state: {
    List:{
      ...init
    },
    Available: {
      ...init
    },
    ids: {
      ...init
    },
    checked: {
      ...init
    },
    modals: {
      ...init
    },
    attributes: {
      ...init
    },
    exportData: {
      ...init
    },
    dropdowns: dropdown_init.dropdowns,
    dropdown_values: {
      'audit_filter_user':[0],
      'audit_filter_action_type':[0],
    }
  },

  mutations: {
    clearLists(state){
      state.List = {...init}
      state.exportData = {...init}
      state.dropdowns = dropdown_init.dropdowns
      state.dropdown_values = dropdown_init.dropdown_values
      state.attributes = {...init}
    },
    setDropdown(state, payload) {
      state.dropdowns[payload.table] = payload.data
    },
    setDropdownValue(state, payload) {
      let _listType = payload.listType || payload.table

      if(!(_listType in state.dropdown_values)) {
        state.dropdown_values[_listType] = []
      }

      if(typeof(payload.idx) != 'undefined' ) {
        state.dropdown_values[_listType][payload.idx] = payload.data
      } else {
        state.dropdown_values[_listType].push(payload.data)
      }
    },    
    clearDropdownValues(state,payload) {
      console.log('Clearing dropdown values',payload)
      state.dropdown_values[payload.listType] = []
    },
    setAttributes(state, payload) {
      state.attributes[payload.listType] = payload.data;
    },
    setExportData(state, payload) {
      state.exportData[payload.listType] = payload.data;
    },    
    setList(state, payload) {
      state.List[payload.listType] = payload.data;
    },
    setListIndex(state, payload) {
      state.List[payload.listType][payload.idx] = payload.data;
    },
    setListIndexProp(state, payload) {
      state.List[payload.listType][payload.idx][payload.prop] = payload.data;
    },
    addNew (state, payload) {
      state.List[payload.listType].push(payload.data);
    },
    removeIndex(state, payload) {
      if (payload.idx > -1) {
        state.List[payload.listType].splice(payload.idx, 1);
      }
    },
    setSingle(state, payload) {
      state.List[payload.listType][payload.index][payload.name] = payload.data;
    },
    setLists(state, payload) {
      state.List[payload.listType] = payload.data.List;
      state.Available[payload.listType] = payload.data.Available;
    },
    setAvailable(state,payload) {
      state.Available[payload.listType] = payload.data;
    },
    setID(state, payload) {
      console.log(payload)
      let myObj = {}
      myObj[payload.idType] = payload._id
      state.ids[payload.listType] = {
        ...state.ids[payload.listType],
        ...myObj
      }
    },
    unsetID(state, payload) {
      console.log(payload)
      let myObj = {}
      myObj[payload.idType] = payload._id
      state.ids[payload.listType] = {
        ...state.ids[payload.listType],
        ...myObj
      }
    },
    setChecked(state,payload)
    {
      if(! state.checked[payload.listType]) 
      { 
        state.checked[payload.listType] = [payload.data]
      }
      else 
      {
        state.checked[payload.listType].push(payload.data)
      }
    },
    removeChecked(state,payload)
    {
      let idx = state.checked[payload.listType].indexOf(payload.data)
      state.checked[payload.listType].splice(idx,1)
    },
    clearChecked(state,payload) {
      state.checked[payload.listType] = []
    },
    showModal(state,payload) {
      state.modals[payload.listType] = true;
    },
    hideModal(state,payload) {
      state.checked[payload.listType] = []
      state.modals[payload.listType] = false;
    },
  },
  actions: {
    async loadDropdown({commit,rootState},payload) {
      let url = rootState.settings.api_base + 'general/dropdowns/'
        + payload.table +'/'
        + payload.valueField + '/'
        + payload.descriptionField
      commit('setDropdown',{...payload,data:[]})
      try {
        let rs = await axios.get(url)
        commit('setDropdown',{...payload,data:rs.data})
      } catch(err) {
        console.error('Failed to load dropdown, error was ',err)
      }
    }, 
    async loadDropdownWhereCondition({commit,rootState},payload) {
      let url = rootState.settings.api_base + 'general/dropdownsWhereCondition/'
        + payload.table +'/'
        + payload.valueField + '/'
        + payload.descriptionField + '/'
        + payload.condition
      commit('setDropdown',{...payload,data:[]})
      try {
        console.log('url drop down is ', url)
        let rs = await axios.get(url)
        commit('setDropdown',{...payload,data:rs.data})
      } catch(err) {
        console.error('Failed to load dropdown, error was ',err)
      }
    }
  }
}
