<template>
  <tr class="text-nowrap" :class="setClass(status)" ref="reference">
    <td>
      <text class="form-control text-center">{{sensor_id}}</text>
    </td>
    <td>
      <input type="number" @change="setAlarmRegister" class="form-control text-center" id="alarmReg" :value="alarm_register"/>
    </td>
    <td>
      <select class="form-control text-center" @change="setAlarmType" :value="alarm_type" id="alarmType">
        <option v-for="type in alarmTypes" :key="type.alarm_type_id" :value="type.alarm_type_id">
          {{ type.type_pretty_name }}
        </option>
      </select>
    </td>
    <td>
      <input type="number" step="0.01"
             @change="setAlarmValue"
             class="form-control text-center"
             id="alarmValue" :value="alarm_value"
      />

      <input v-if="alarm_type == 4 || alarm_type == 5"
             type="number" @change="setHysteresisValue"
             class="form-control text-center" id="hysteresisValue"
             :value="hysteresis"
      />
    </td>
    <td>
      <Multiselect
          style="background: transparent"
          class="form-control w-auto"
          :options="toWhom"
          v-model="selectedNotifications"
          label="displayname"
          track-by="displayname"
          placeholder=""
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :searchable="false"
          open-direction="below"
          :show-labels="false"
          @close="setNotifications"
      >
        <!-- Slot for the collapsed selection view -->
        <template #selection="{ values }">
      <span class="multiselect__single">
        {{ values.length }} users selected
      </span>
        </template>
        <template #option="{ option }">
          <div class="d-flex align-items-center">
            <i v-if="selectedNotifications?.some(selected => selected.displayname === option.displayname)"
                  class="bi bi-check custom-checkmark"/>
            {{ option.displayname }}
          </div>
        </template>
      </Multiselect>
    </td>
    <td>
    <select class="form-control text-center" @change="setAlarmAction" :value="alarm_action" id="alarmType">
        <option v-for="type in alarmActionTypes" :key="type.alarm_action_type_id" :value="type.alarm_action_type_id">
          {{ type.type_pretty_name }}
        </option>
      </select>
    </td>
    <td>
      <button class="form-control" @click="removeAlarm(array_idx)">X</button>
    </td>
  </tr>
</template>

<script>
  import { useStore } from "vuex";
  import {computed, ref} from "vue";
  import Multiselect from "vue-multiselect";

  export default {
    components: {
      Multiselect
  },
    props: {
      sensor_id: String,
      alarm_type: String,
      alarm_value: String,
      alarm_register: String,
      alarm_action: String,
      hysteresis: String,
      status: String,
      array_idx: Number,
      id: Number,
    },
    setup(props) {
      const store = useStore();
      
      const removeAlarm = function () {
        // remove the alarm from the db
        store.commit('alarm/updateAlarmStatus', {
          id: props.id,
          status: 'deleted'
        });
        // update the overview
        store.dispatch('gateway/findInfoOverview', {id: store.state.gateway.id});
      }

      const setAlarmRegister = function(e) {
        store.commit('alarm/setRegister', {
          id: props.id,
          alarm_register: e.target.value
        });
      }

      const setAlarmValue = function(e) {
        store.commit('alarm/setSetPoint', {
          id: props.id,
          alarm_set_point: e.target.value
        });
      }

      const setAlarmType = (e) => {
        store.commit('alarm/setAlarmType', {
          id: props.id,
          alarm_type_id: e.target.value
        });
      }

      const setHysteresisValue = (e) => {
        store.commit('alarm/setHysteresis', {
          id: props.id,
          hysteresis: e.target.value
        });
      };

      const selectedNotifications = ref(store.state.alarm.alarm_set[props.array_idx].selected_notify);

      const setNotifications = (e) => {
        // if the arrays are different, update the status
        if (JSON.stringify(store.state.alarm.alarm_set[props.array_idx].selected_notify) !== JSON.stringify(e)) {
          store.commit('alarm/setSelectedNotifications', {
            id: props.id,
            selected_notify: e
          });
        }
      };

      const setAlarmAction = (e) => {
        store.commit('alarm/setAlarmActionType', {
          id: props.id,
          alarm_action_type_id: e.target.value
        });
      };

      const calcSelected = (values) => values?.length || 0;

      const setClass = (status) => {
        if (status === 'existing') return;
        return status == 'deleted' ? 'strikeout' : status;
      }

      return {
        removeAlarm,
        setAlarmRegister,
        setAlarmValue,
        alarmTypes: computed(() => store.state.alarm.alarm_type_options),
        setAlarmType,
        setHysteresisValue,
        setNotifications,
        selectedNotifications,
        setAlarmAction,
        alarmActionTypes: computed(() => store.state.alarm.alarm_action_type_options),
        calcSelected,
        setClass,
        toWhom: computed(() => store.state.alarm.alarm_set[props.array_idx].toWhom),
      };
    },
  };
</script>
<style scoped>
li {
  cursor: pointer;
}

tr.new td {
  border: 3px solid #41b883;
}

tr.updated td {
  border: 3px solid orange;
}

tr.strikeout td {
  position: relative;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 49%;
  left: 0;
  width: 100%;
  border-bottom: 3px solid var(--color-txt);
  z-index: 1000;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
  .multiselect__option--highlight.multiselect__option--selected {
    background-color: var(--color-bg) !important;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background-color: var(--color-bg) !important;
  }

  .multiselect__option--highlight {
    background-color: var(--color-bg) !important;
    color: var(--color-txt) !important;
  }

  .d-flex.align-items-center .custom-checkmark {
    color: green !important;
    margin-right: 4px !important;
    font-size: 0.9em !important;
    height: 1em;
    line-height: 1;
    vertical-align: middle;
    transform: scale(1.5);
    transform-origin: center;
  }
</style>
