<template>
  <div ref="sparklineContainer" :style="sparklineStyle"></div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, toRaw } from 'vue';
import { useStore } from 'vuex';
import * as d3 from 'd3';

const props = defineProps({
  readings: Object
});

const store = useStore();

const sparklineContainer = ref(null);

const sparklineStyle = {
  width: '100%',
  height: '50px',
  border: '1px solid #ccc',
  boxShadow: 'inset 4px 4px 16px rgba(0, 0, 0, 0.2)',
  padding: '5px',
  backgroundColor: 'var(--btn-pressed)',
  borderRadius: '4px',
  position: 'relative',
};


const drawSparkline = () => {
  if (!sparklineContainer.value) return;

  d3.select(sparklineContainer.value).selectAll('*').remove();

  const margin = { top: 5, right: 5, bottom: 5, left: 5 };
  const width = sparklineContainer.value.clientWidth - margin.left - margin.right;
  const height = sparklineContainer.value.clientHeight - margin.top - margin.bottom;

  // Convert readings object to array and use the first register's data
  let readingsArray = Object.values(props.readings)[0];
  let rawData = toRaw(readingsArray);

  // Prepare the data for the line chart
  const data = rawData.map(d => ({
    date: new Date(d.receive_datetime),
    value: d.sensor_data_value,
  }));

  const svg = d3.select(sparklineContainer.value)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

  // Define a filter for the glow effect
  const defs = svg.append('defs');
  const filter = defs.append('filter')
      .attr('id', 'glow');

  filter.append('feGaussianBlur')
      .attr('stdDeviation', '3.5')
      .attr('result', 'coloredBlur');

  const feMerge = filter.append('feMerge');
  feMerge.append('feMergeNode')
      .attr('in', 'coloredBlur');
  feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic');

  // Use d3.scaleTime for the x-axis
  const x = d3.scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([0, width]);

  const y = d3.scaleLinear()
      .domain([d3.min(data, d => d.value), d3.max(data, d => d.value)])
      .range([height, 0]);

  const line = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

  svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#F7941D')
      .attr('stroke-width', 1.5)
      .attr('filter', 'url(#glow)')
      .attr('d', line);
};

onMounted(() => {
  if (props.readings) {
    drawSparkline();
  }
});

// Watch for changes in the readings prop
watch([() => props.readings, () => store.state.settings.is_loading], ([newReadings, loading]) => {
  if (!loading && newReadings && Object.keys(newReadings).length > 0) {
    drawSparkline();
  }
}, { deep: true });
</script>

<style scoped>
</style>
