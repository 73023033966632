<template>
  <D3Chart v-if="isLoaded" :data="sensorData" :ai="aiData"/>
  <div class='row justify-content-center'>
    <div class='col-md-3'>
      <div class="d-flex flex-row" v-if="isLoaded">
        <div class="p-1">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault1" v-model="chartType"
                   value="line" />
            <font-awesome-icon icon="chart-line" title="Line Chart" size="lg"/>
          </div>
        </div>
        <div class="p-1" v-if="sensorModel == 13">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault2" v-model="chartType"
                   value="wind" />
            <font-awesome-icon icon="wind" title="Wind Chart" size="lg"/>
          </div>
        </div>
        <div class="p-1">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault3" v-model="chartType"
              value="bar" />
            <font-awesome-icon icon="chart-bar" title="Bar Chart" size="lg"/>
          </div>
        </div>
        <div class='p-1'> | </div>
        <div class='p-1'>
          <export-data columnHeaders="" dataSourceKey="sensor_readings" outputFileName="ChartReadings.csv" />
        </div>
      </div>
    </div>
  </div>

  <chart-filter :listType="listType" />
  <unresponsive-data-modal />
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import ChartFilter from './chartFilters.vue'
import ExportData from '../Export/ExportData.vue'
import UnresponsiveDataModal from '../modals/chart-unresponsive-modal'
import D3Chart from "@/components/shared/charts/D3Chart.vue";

export default {
  name: "ApgBarChart",
  components: {
    D3Chart,
    ChartFilter,
    UnresponsiveDataModal,
    ExportData,
  },
  props: {
    listType: String,
  },
  setup(props) {
    const store = useStore();
    const chartFields = ['sensor_id', 'address', 'sensor_data_value', 'receive_datetime', 'sensor_address'];

    const chartType = computed({
      get() {
        return store.state.chart.type[props.listType]
      },
      set(value) {
        store.dispatch("chart/setChartType", { listType: props.listType, chartType: value }, { root: true });
      }
    });

    return {
      regToRead: computed(() => store.state.chart.register_to_read?.toString()),
      sensorData: computed(() => store.state.chart.data[props.listType]),
      aiData: computed(() => store.state.chart.data['ai_predictions']),
      rawData: computed(() => store.state.chart.rawData[props.listType]),
      isLoaded: computed(() => !store.state.settings.is_loading),
      showAi: computed(() => store.state.chart.showing_predictions),
      sensorModel: computed(() => store.state.apgList.List?.sensor_attribute?.find((item) =>
          item.attribute_name === 'SensorModel')?.attribute_value),
      chartType,
      chartFields,
    }
  }
}
</script>
<style>
canvas {
  min-width: 100%;
  min-height: 100%;
}
</style>
