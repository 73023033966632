<template>
  <div v-if="data && data.length">
    <ul>
      <li v-for="group in data" :key="group.id">
        <span class="group" @click="groupClick(group)" :class="{ 'active': isActive(group.id, 'group')}">
          <i v-if="group.nodes && group.nodes.length" :class="group.expanded ? 'bi bi-caret-down bi' : 'bi bi-caret-right bi-xs'"></i>
          {{ group.label }}
          <i v-if="group.nodes?.some(g => getGateway(g.id)?.message_queue_count > 0)" class="bi bi-envelope text-warning" style="padding-left: 10px"/>
          <i v-if="group.nodes?.some(g => getGateway(g.id)?.alarm_count_active > 0)" class="bi bi-alarm text-danger" style="padding-left: 10px"/>
        </span>
        <ul v-if="group.expanded">
          <li v-for="gateway in group.nodes" :key="gateway.id">
            <span class="gateway" @click="gatewayClick(gateway)" :class="{ 'active': isActive(gateway.id, 'gateway')}">
              <i v-if="gateway.nodes && gateway.nodes.length && gateway.label" :class="gateway.expanded ? 'bi bi-caret-down bi-xs' : 'bi bi-caret-right bi-xs'"></i>
              {{ gateway.label }}
              <i v-if="parseInt(getGateway(gateway.id)?.message_queue_count) > 0" class="bi bi-envelope text-warning" style="padding-left: 10px"/>
              <i v-if="parseInt(getGateway(gateway.id)?.alarm_count_active) > 0" class="bi bi-alarm text-danger" style="padding-left: 10px"/>
            </span>
            <ul v-if="gateway.expanded">
              <li v-for="sensor in gateway.nodes" :key="sensor.id" @click="sensorClick(sensor)">
                <span class="sensor" v-if="sensor.label" :class="{ 'active': isActive(sensor.id, 'sensor')}">
                  {{ sensor.label }}
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed, onMounted} from "vue";
export default {
  name: 'TreeList',
  props: {
    source: String,
  },
  setup(props) {
    const store = useStore();
    const data = computed(() => store.state.MyView.data[props.source]);

    onMounted(() => {
      store.commit('apgList/clearLists', {});
      store.commit('chart/clearData', { listType: props.listType }, { root: true });
    });

    // Event handlers
    const groupClick = (group) => store.dispatch('MyView/click', {id: group.id, level: 'group', toggle: true});
    const gatewayClick = (gateway) => store.dispatch('MyView/click', {id: gateway.id, level: 'gateway', toggle: true});
    const sensorClick = (sensor) => store.dispatch('MyView/click', {id: sensor.id, level: 'sensor', toggle: true});

    const getGateway = (id) => {
      return store.getters["MyView/getGateway"](id);
    };

    const isActive = (id, level) => {
      let selected = store.state.MyView.selected[level];
      return selected.id === id;
    };

    return {
      data,
      groupClick,
      gatewayClick,
      sensorClick,
      getGateway,
      isActive
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  cursor: pointer;
  padding: 1px;
  margin: 0;
}

.group {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.gateway {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.sensor {
  display: flex;
  align-items: center;
  padding-left: 60px;
}

span i {
  margin-right: 4px;
}

i {
  font-size: .7rem;
}

span {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.active {
  color: var(--active);
  transition: background 0.25s ease, color 0.25s ease;
}

.group:hover,
.gateway:hover,
.sensor:hover {
  background-color: var(--active);
  color: var(--color-txt);
}
</style>

